// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


ReactDOM.render(<App />, document.getElementById('root'));


// TOOD: NOTIFICATION CODE
// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("/firebase-messaging-sw.js")
//         .then((registration) => {
//             console.log("Service Worker registered successfully:", registration);
//         })
//         .catch((error) => {
//             console.error("Service Worker registration failed:", error);
//         });
// }
