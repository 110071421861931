import React from "react";
import { Collapse, Nav, NavItem, NavLink } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Constants, Dispatcher, Store } from "../../../flux";
import { Language } from "../../../config";


class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
      selectedMenu: Store.getSelectedCategories(),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
      selectedMenu: Store.getSelectedCategories()
    });
  }

  render() {
    const { navItems: items, selectedMenu } = this.state;
    console.log('items in navItems', items)
    console.log('selectedMenu', selectedMenu)
    return (
      <div style={{ overflowY: 'auto', maxHeight: '85vh', paddingBottom: '60px' }}>
        {
          items.map((item, index) => {
            let category = item.category
            if (item.isLogout) {
              return (
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  borderTop: 1,
                  borderStyle: 'solid',
                  borderTopColor: 'rgba(255, 255, 255, .5)',
                  height: '3.78rem',
                  width: '100%',
                  background: '#2F1F9C'
                }}>
                  <Nav className="nav--no-borders flex-column">
                    <SidebarNavItem key={index} item={item} />
                  </Nav>
                </div>
              )
            } else {
              return (
                <div key={index}>
                  <Nav className="nav--no-borders flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          Dispatcher.dispatch({
                            actionType: Constants.SELECTED_CATEGORY,
                            payload: category.title
                          });
                        }}
                      >
                        {category.htmlBefore && (
                          <div
                            className="d-inline-block item-icon-wrapper"
                            dangerouslySetInnerHTML={{ __html: category.htmlBefore }}
                          />
                        )}
                        {category.title && (
                          <span> {Language === "en" ? category.title : category.title_es} </span>
                        )}
                        {category.htmlAfter && (
                          <div
                            className="d-inline-block item-icon-wrapper"
                            dangerouslySetInnerHTML={{ __html: category.htmlAfter }}
                          />
                        )}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Collapse open={this.state.selectedMenu.includes(category.title)}>
                    <div>
                      <Nav className="nav--no-borders flex-column" style={{ paddingLeft: '12px' }}>
                        {item.items.map((subItem, idx) => (
                          <SidebarNavItem key={idx} item={subItem} />
                        ))}
                      </Nav>
                    </div>
                  </Collapse>
                </div>
              )
            }
          })
        }
      </div>
    )
  }
}
export default SidebarNavItems;
