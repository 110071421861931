import { RESTAURANT_ADMIN, RESTAURANT_CHEF, RESTAURANT_WAITER } from "../config";

export default function () {
  let hasRestaurantPermission = false;
  let restaurantUserRole = "";
  let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
  let userData = JSON.parse(localStorage.getItem("user_info"));
  let userInfo = userData && userData['data'];
  if (organizationData !== undefined && organizationData !== null &&
    organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
  ) {
    hasRestaurantPermission = organizationData['data']['restaurantModule'];
  }
  console.log('userData', userInfo)
  if (userInfo && userInfo['restaurantUserRole']) {
    restaurantUserRole = userInfo['restaurantUserRole'];
  }

  console.log('restaurantUserRole', restaurantUserRole)

  let items = [];


  const mainMenuItems = [
    {
      title_es: "Menú Principal",
      title: "Dashboard ES",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title_es: "Ventas",
      title: "Ventas Es",
      htmlBefore: '<i class="material-icons">receipt_long</i>',
      to: "/ventas"
    },
    {
      title_es: "Items",
      title: "Items Es",
      htmlBefore: '<i class="material-icons">list_alt</i>',
      to: "/items"
    },
    {
      title_es: "Clientes",
      title: "Customers Es",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/customers"
    },
    {
      title_es: "Folios",
      title: "Folios Es",
      htmlBefore: '<i class="material-icons">card_travel</i>',
      to: "/folios"
    },
    {
      title_es: "Reportes",
      title: "Reports Es",
      htmlBefore: '<i class="material-icons">analytics</i>',
      to: "/charts"
    },
    // This menu hide at 2024-11-25 by harshit because client 
    // {
    //   title_es: "Edita tu perfil",
    //   title: "Edit Profile ES",
    //   htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
    //   to: "/edit-user-profile"
    // },
  ]

  const users = {
    title_es: "Usuarios",
    title: "Users Es",
    htmlBefore: '<i class="material-icons">assignment_ind</i>',
    to: "/users"
  };
  const PurchaseDocuments = {
    title_es: "Documentos de compra",
    title: "Documentos de compras",
    htmlBefore: '<i class="material-icons">topic</i>',
    to: "/PurchaseDocuments"
  };

  if (hasRestaurantPermission) {
    if (restaurantUserRole && restaurantUserRole === RESTAURANT_ADMIN) {
      mainMenuItems.push(users);
      arrayIndexMove(mainMenuItems, mainMenuItems.length - 1, 3)
      mainMenuItems.push(PurchaseDocuments);
      arrayIndexMove(mainMenuItems, mainMenuItems.length - 1, 6)
    }
  } else {
    mainMenuItems.push(users);
    arrayIndexMove(mainMenuItems, mainMenuItems.length - 1, 3)
    mainMenuItems.push(PurchaseDocuments);
    arrayIndexMove(mainMenuItems, mainMenuItems.length - 1, 6)
  }

  const mainMenuCategoryObj = {
    title_es: "Menú Principal",
    title: "Dashboard ES",
    htmlBefore: '<i class="material-icons">dashboard</i>',
    htmlAfter: '<i class="material-icons">arrow_drop_down</i>'
  }
  const mainMenuObj = generateCategoryObject(mainMenuCategoryObj, mainMenuItems)
  console.log('mainMenuObj : ', mainMenuObj);


  let restaurantMenuItems = []

  if (hasRestaurantPermission) {
    let createSpace = {
      title_es: "Crear Espacios",
      title: "Create Spaces",
      htmlBefore: '<i class="material-icons">food_bank</i>',
      to: "/restaurant-spaces"
    }
    let createTable = {
      title_es: "Crear Mesas",
      title: "Create Tables",
      htmlBefore: '<i class="material-icons">table_restaurant</i>',
      to: "/tables"
    }
    let createKitchen = {
      title_es: "Crear Cocinas",
      title: "Create Kitchens",
      htmlBefore: '<i class="material-icons">kitchen</i>',
      to: "/restaurant-kitchens"
    }
    let createBar = {
      title_es: "Crear Barras",
      title: "Create Bars",
      htmlBefore: '<i class="material-icons">local_bar</i>',
      to: "/restaurant-bars"
    }
    let restaurantTableWithFilters = {
      title_es: "Restaurante",
      title: "Restaurant",
      htmlBefore: '<i class="material-icons">grid_view</i>',
      to: "/restaurant"
    }
    let restaurantOrders = {
      title_es: "Orden de restaurante",
      title: "Restaurant Order",
      htmlBefore: '<i class="material-icons">grid_view</i>',
      to: "/restaurant-orders"
    }
    let restaurantReport = {
      title_es: "Informe del restaurante",
      title: "Restaurant Report",
      htmlBefore: '<i class="material-icons">grid_view</i>',
      to: "/restaurant-report"
    }

    if (restaurantUserRole && restaurantUserRole === RESTAURANT_ADMIN) {
      restaurantMenuItems.push(restaurantTableWithFilters);
      restaurantMenuItems.push(restaurantOrders);
      restaurantMenuItems.push(restaurantReport);
      restaurantMenuItems.push(createSpace);
      restaurantMenuItems.push(createTable);
      restaurantMenuItems.push(createKitchen);
      restaurantMenuItems.push(createBar);
    }

    if (restaurantUserRole && restaurantUserRole === RESTAURANT_WAITER) {
      restaurantMenuItems.push(restaurantTableWithFilters);
      restaurantMenuItems.push(restaurantReport);
    }

    if (restaurantUserRole && restaurantUserRole === RESTAURANT_CHEF) {
      restaurantMenuItems.push(restaurantOrders);
    }
  }

  const restaurantMenuCategoryObj = {
    title_es: "Restaurante",
    title: "Restaurant",
    htmlBefore: '<i class="material-icons">grid_view</i>',
    htmlAfter: '<i class="material-icons">arrow_drop_down</i>'
  }


  const restaurantMenuObj = generateCategoryObject(restaurantMenuCategoryObj, restaurantMenuItems);
  console.log('restaurantMenuObj', restaurantMenuObj)

  const logoutMenuObj = {
    title_es: "Cerrar sesión",
    title: "Logout ES",
    htmlBefore: '<i class="material-icons" >&#xE879;</i>',
    to: "/logout",
    isLogout: true
  }
  items.push(mainMenuObj);
  if (hasRestaurantPermission) {
    items.push(restaurantMenuObj);
  }
  items.push(logoutMenuObj);

  console.log('items', items);
  return items;
}

function arrayIndexMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}


function generateCategoryObject(categoryObj, categoryItems) {
  return {
    'category': categoryObj,
    'items': categoryItems
  }
}