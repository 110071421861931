import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, FormInput, FormTextarea, Alert } from 'shards-react'
import { strings } from '../../localization';
import PageTitle from '../../components/common/PageTitle';
import './Restaurant.css';
import FoodInstructionDialog from '../../components/common/FoodInstructionDialog';
import { API_URL, formateNumber } from '../../config';
import { Tabs, Tab } from 'react-bootstrap';
import RestaurantFoodItemList from '../../components/Restaurant/RestaurantFoodItemList';
import RestaurantFoodItemRow from '../../components/Restaurant/RestaurantFoodItemRow';

function RestaurantFoodSelection(props) {
    console.log('props of food selection', props);
    const { id, orderId, draftFoodItems, handleBackButton } = props;


    // food name input states
    const [searchedFoodName, setSearchedFoodName] = useState("");

    // edit food items states
    const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [editFoodItemIndex, setEditFoodItemIndex] = useState('');
    const [isFoodItemSaveButtonLoading, setIsFoodItemSaveButtonLoading] = useState(false);
    const [itemTypes, setItemTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [foodItems, setFoodItems] = useState([]);
    const [foodItemData, setFoodItemData] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    // alert states
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('danger');


    console.log('table id', id)
    console.log('orderId', orderId);

    const CommonBtn = ({ name, onClick, isLoading, marginLeft, marginRight }) => {
        return (
            <Button
                theme="primary"
                className="mx-1"
                style={{ width: 120, height: 40, marginLeft: marginLeft, marginRight: marginRight }}
                onClick={onClick}
            >
                {
                    isLoading
                        ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                        : name
                }
            </Button>
        )
    }

    const getFoodItems = async () => {
        let statusCode = 0;
        const apiUrl = `${API_URL}items`
        console.log('apiUrl', apiUrl);
        fetch(apiUrl, {
            method: 'get',
            credentials: 'include'
        })
            .then((res) => {
                statusCode = res.status;
                return res.json()
            })
            .then((res) => {
                console.log('items get api response', res)
                if (statusCode === 200) {
                    var data = res.result;
                    if (data.length > 0) {
                        let draftItems = draftFoodItems ? draftFoodItems : [];
                        // This block is used to manage draft items which got from route params
                        if (draftItems && draftItems.length > 0) {
                            console.log('draftItems in getFoodItems fun', draftItems);
                            draftItems.map((draftItem, index) => {
                                let matchedFoodIndex = data.findIndex((foodItem) => foodItem['_id'] === draftItem["itemID"]);
                                if (matchedFoodIndex !== -1) {
                                    console.log('matchedFoodItem', matchedFoodIndex);
                                    data[matchedFoodIndex]['data']['quantity'] = draftItem['quantity'];
                                    data[matchedFoodIndex]['data']['note'] = draftItem['note'] ? draftItem['note'] : "";
                                }
                            })
                        }
                        setFoodItems(data);
                        setFoodItemData(data);
                    } else {
                        setFoodItems([]);
                        setFoodItemData([]);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log('items get api catch block err', err)
                setIsLoading(false);
            });
    }

    // This function is used to call restaurant item type get api
    const getItemTypeData = async () => {
        setIsLoading(true);
        fetch(`${API_URL}restaurant-item-type`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                console.log('response of restaurant item type get api', res);
                let result = res['result'];
                if (result && result.length > 0) {
                    setItemTypes(result);
                } else {
                    setItemTypes([]);
                }
                getFoodItems();
            })
            .catch(err => {
                console.log('catch block error at getting restaurant type', err);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getItemTypeData();
        const offcanvasBody = document.querySelector('.offcanvas-body');
        if (offcanvasBody) {
            offcanvasBody.classList.add('restaurant-canvas-body');
        }

        return () => {
            if (offcanvasBody) {
                offcanvasBody.classList.remove('restaurant-canvas-body');
            }
        };
    }, [])

    const handleEditFoodItem = (foodItem, index) => {
        console.log('foodItem in edit food item', foodItem)
        setIsEditDialogVisible(true);
        setEditFoodItemIndex(index);
        setEditFoodDialogItem(foodItem);
    }

    // This function is used when increase or decrease item quantity
    const manageFoodQuantity = (foodItem, index, isFromIncrement) => {
        console.log('foodItem', foodItem);
        console.log('index', index);
        const oldFoodItems = [...foodItems];
        // let foodItemQty = parseFloat(oldFoodItems[index]['data']['quantity']);
        // const foodItemStock = parseFloat(oldFoodItems[index]['data']['stock']);
        const matchedData = oldFoodItems.find((item) => item._id === foodItem._id);
        console.log('matchedData', matchedData)
        let foodItemQty = matchedData['data']['quantity'] ? parseFloat(matchedData['data']['quantity']) : 0;
        const foodItemStock = matchedData['data']['stock'] ? parseFloat(matchedData['data']['stock']) : '0';
        if (isFromIncrement) {
            let tempFoodItemQty = foodItemQty;
            tempFoodItemQty++;
            if (tempFoodItemQty <= foodItemStock) {
                foodItemQty++;
            } else {
                // TODO: display snack bar
                showAlert(strings.item_out_of_stock_err, 'danger');
            }
        } else {
            if (foodItemQty !== 0) {
                foodItemQty--;
            }
            if (foodItemQty === 0) {
                matchedData['data']['note'] = "";
            }
        }
        matchedData['data']['quantity'] = foodItemQty.toString();
        console.log('oldFoodItems before set', oldFoodItems);
        setFoodItems([...oldFoodItems]);
    }

    const handleFoodItemDialogSaveButtonPressed = (updatedItem, index) => {
        setIsLoading(true);
        console.log('updatedItem', updatedItem);
        console.log('index', index);
        const oldFoodItemsData = [...foodItems];
        const oldItemsDataArray = [...foodItemData];
        const matchedData = oldFoodItemsData.find((item) => item._id === updatedItem._id);
        const matchedIndex = oldFoodItemsData.findIndex((item) => item._id === updatedItem._id);
        // const foodItemsId = oldFoodItemsData[index]['_id'];
        // oldFoodItemsData[index]['data']['quantity'] = updatedItem['quantity'];
        // if (updatedItem['note']) {
        //     oldFoodItemsData[index]['data']['note'] = updatedItem['note'];
        // }
        // console.log('old food item index', oldFoodItemsData[index]);
        // const updateItemIndex = oldItemsDataArray.findIndex((item, index) => item['_id'] === foodItemsId);
        // if (updateItemIndex && updateItemIndex !== -1) {
        //     oldItemsDataArray[updateItemIndex]['data']['quantity'] = updatedItem['quantity'];
        //     if (updatedItem['note']) {
        //         oldItemsDataArray[updateItemIndex]['data']['note'] = updatedItem['note'];
        //     }
        // }
        // setFoodItems([...oldFoodItemsData]);
        // setFoodItemData([...oldItemsDataArray]);
        // setIsEditDialogVisible(false);
        // setIsLoading(false);


        if (updatedItem['note']) {
            oldFoodItemsData[matchedIndex]['data']['note'] = updatedItem['note'];
        }
        const updateItemIndex = oldItemsDataArray.findIndex((item, index) => item['_id'] === matchedData['_id']);
        if (updateItemIndex && updateItemIndex !== -1) {
            if (updatedItem['note']) {
                oldItemsDataArray[updateItemIndex]['data']['note'] = updatedItem['note'];
            }
        }
        setFoodItems([...oldFoodItemsData]);
        setFoodItemData([...oldItemsDataArray]);
        setIsEditDialogVisible(false);
        setIsLoading(false);
    }

    const searchItemFromFoodItems = (value) => {
        setSearchedFoodName(value);
        setIsLoading(true);
        let filteredData = [...foodItemData];
        if (searchedFoodName.length > 0) {
            filteredData = foodItemData.filter((item, index) => item['data']['name'].toLowerCase().includes(value.toLowerCase()))
        }
        console.log('searchItemFromFoodItems func filteredData', filteredData);
        setFoodItems([...filteredData]);
        setIsLoading(false);
    }

    const orderSummaryApi = async (draftFoodItems = []) => {
        const tableId = id;
        let bodyData = {};
        let foodItemsDataArray = [];
        draftFoodItems.map((item) => {
            console.log('item', item)
            let singleItem = {
                itemID: item['_id'],
                note: item['data']['note'] ? item['data']['note'] : "",
                quantity: item['data']['quantity'],
                price: item['data']['price']
            };
            console.log('selected food singleItem', singleItem);
            foodItemsDataArray.push(singleItem);
        })
        bodyData['dataArray'] = foodItemsDataArray;
        bodyData['restaurantTableID'] = tableId;
        console.log('bodyData of order summary api before send', bodyData);
        let statusCode = 0;

        let apiUrl = `${API_URL}restaurant-order`;
        if (orderId) {
            apiUrl += `/${orderId}`
        }
        console.log('order summary api url', apiUrl);
        fetch(apiUrl, {
            method: orderId ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((res) => {
            console.log('response of order summary api', res);
            if (statusCode === 200) {
                console.log('200 response of order summary api', res);
                setIsButtonLoading(false);
                handleBackButton(true, orderId ? orderId : res['_id'])
            } else {
                const reponseMessage = res['message'] ? res['message'] : "Something went wrong!"
                showAlert(reponseMessage, 'danger');
                setIsButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at order summary api', err);
            setIsButtonLoading(false);
        })
    }

    const handleAddCartButtonPressed = () => {
        if (foodItemData && foodItemData.length > 0) {
            let addedOrEditedFoodItems = foodItemData.filter((item) => item['data']['quantity'] && item['data']['quantity'] > 0);
            if (addedOrEditedFoodItems && addedOrEditedFoodItems.length > 0) {
                setIsButtonLoading(true);
                orderSummaryApi(addedOrEditedFoodItems);
            } else {
                showAlert(strings.please_select_food, 'danger');
            }
        }
    }

    const showAlert = (msg, type) => {
        setIsAlertVisible(true);
        setAlertType(type);
        setAlertMsg(msg);

        setTimeout(() => {
            setIsAlertVisible(false);
        }, 4000)
    }

    const buildFoodItems = () => {
        let itemsFilteredData = [];
        if (itemTypes && itemTypes.length > 0) {
            itemTypes.map((item) => {
                const itemType = item.value;
                const filteredData = foodItemData.filter((item, index) => item['data']['restaurantItemType'] && item['data']['restaurantItemType']['value'] === itemType);
                if (filteredData && filteredData.length > 0) {
                    itemsFilteredData.push({
                        itemType: item,
                        data: filteredData
                    })
                }
            })
        }
        if (itemsFilteredData.length > 1) {
            return (
                <>
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 10 }}>
                        <Tabs
                            id="justify-tab-example"
                            className="mb-3"
                            fill
                            style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                flexWrap: 'nowrap'
                            }}
                        >
                            {
                                itemsFilteredData.map((singleFoodItem) => {
                                    const itemType = singleFoodItem.itemType;
                                    const filteredFoodData = singleFoodItem.data;
                                    const filteredData = filteredFoodData.filter((item, index) => item['data']['restaurantItemType'] && item['data']['restaurantItemType']['value'] === itemType.value);
                                    if (filteredData && filteredData.length > 0) {
                                        return (
                                            <Tab eventKey={itemType.label} title={itemType.label}>
                                                <RestaurantFoodItemList
                                                    foodItems={filteredData}
                                                    filteredData={filteredData}
                                                    handleEditBtnPressed={handleEditFoodItem}
                                                    manageFoodQuantity={manageFoodQuantity}
                                                />
                                            </Tab>
                                        )
                                    }
                                })
                            }
                        </Tabs>
                    </div>
                </>
            )
        } else {
            return (
                <div style={{ marginTop: 10 }}>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <FormInput
                            type="text"
                            value={searchedFoodName}
                            placeholder={strings.search_by_food_name}
                            onChange={e => searchItemFromFoodItems(e.target.value)}
                        />
                    </div>
                    <div style={{ maxHeight: '78vh', overflowY: 'auto', paddingBottom: '45px' }}>
                        {
                            foodItems.length > 0 ? foodItems.map((item, index) => {
                                console.log('foodItems in food item list :', item);
                                return (
                                    <>
                                        <RestaurantFoodItemRow
                                            foodItem={item}
                                            foodIndex={index}
                                            handleEditBtnPressed={handleEditFoodItem}
                                            manageFoodQuantity={manageFoodQuantity}
                                            isFromSummary={false}
                                        />
                                    </>
                                )
                            })
                                : <div className='parent'>
                                    <h4>{strings.no_data_available}</h4>
                                </div>
                        }
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={{ maxHeight: '100vh' }}>
            <p className='mt-10 mb-10' style={{ fontSize: 20 }}>{strings.select_food}</p>
            {buildFoodItems()}

            {
                isEditDialogVisible &&
                <FoodInstructionDialog
                    showModal={isEditDialogVisible}
                    setShowModal={setIsEditDialogVisible}
                    foodItem={editFoodDialogItem}
                    foodIndex={editFoodItemIndex}
                    onSaveButtonPressed={handleFoodItemDialogSaveButtonPressed}
                    isSaveButtonLoading={isFoodItemSaveButtonLoading}
                    isFromFoodSelection={true}
                    showSaveButton
                />
            }
            <Alert
                open={isAlertVisible}
                theme={alertType}
                style={{
                    position: 'fixed',
                    bottom: 30,
                    marginRight: 10
                }}
            >
                {alertMsg}
            </Alert>
            <div className='foodSelectionbtnContainer'>

                <Button
                    theme="primary"
                    className="backBtn"
                    onClick={() => handleBackButton(false)}
                >
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                            : strings.back
                    }
                </Button>
                <Button
                    theme="primary"
                    className="foodAddBtn"
                    onClick={handleAddCartButtonPressed}
                >
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                            : strings.add_cart
                    }
                </Button>
            </div>
        </div>
    )
}

export default RestaurantFoodSelection