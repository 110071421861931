import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormInput, Row } from 'shards-react'
import ReactDatatable from "@ashvin27/react-datatable";
import PageTitle from '../../components/common/PageTitle'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Offcanvas from 'react-bootstrap/Offcanvas';
import swal from 'sweetalert2';
import { strings } from '../../localization';
import { API_URL, LIMIT } from '../../config';
import RestaurantOrderDetails from './RestaurantOrderDetails';
window.Swal = swal;

let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

let start_date = '01' + '/' + mm + '/' + yyyy;
let end_date = dd + '/' + mm + '/' + yyyy;

let intitalStartDateApi = yyyy + '-' + mm + '-' + '01';
let intialEndDateApi = yyyy + '-' + mm + '-' + dd;
const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'
};

function RestaurantReport() {

    const [searchedOrderNo, setSearchedOrderNo] = useState("");

    // date range states
    const [locale, setLocale] = React.useState({ format: 'DD/MM/YYYY' });
    const [startDate, setStartDate] = React.useState(start_date);
    const [endDate, setEndDate] = React.useState(end_date);
    const [startDateApi, setStartDateApi] = React.useState(intitalStartDateApi);
    const [endDateApi, setEndDateApi] = React.useState(intialEndDateApi);
    const startDateApiRef = useRef(intitalStartDateApi);
    const endDateApiRef = useRef(intialEndDateApi);

    // table states
    const [records, setRecords] = useState([]);
    const [limit, setLimit] = useState(LIMIT);

    // pagination states
    const [skip, setSkip] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [prev, setPrev] = useState(true);
    const [next, setNext] = useState(false);

    // sidebar states
    const [isSidebarShow, setIsSidebarShow] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');

    // billing status dropdown states
    const [billingType, setBillingType] = useState('all')
    const billingTypeRef = useRef('all');
    const [billingTypes, setBillingTypes] = useState([
        { label: strings.with_bill, value: 'with_bill' },
        { label: strings.with_invoice, value: 'with_invoice' },
        { label: strings.without_bill_invoice, value: 'without_bill_invoice' },
        { label: strings.all, value: 'all' },
    ])

    // This order status key is pased in api params and in future if we need implement
    // filters based on order status then use below state
    // order status values ==> paid_only || unpaid_orders || all
    const [orderStatus, setOrderStatus] = useState('paid_only')
    const [isBtnLoading, setIsBtnLoading] = useState(false)

    const config = {
        page_size: 100000,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false)

    const handleCallback = (start, end) => {
        var startDate = new Date(start);
        console.log(`startDate is ${startDate} `);
        let formatStartDate = startDate.toDateString().split(' ');
        let startDateMonth = months[formatStartDate[1]];
        let startDateApi =
            formatStartDate[3] + '-' + startDateMonth + '-' + formatStartDate[2];
        console.log(
            '🚀 ~ file: index.js ~ line 532 ~ Customers ~ startDateApi1',
            startDateApi
        );
        var endDate = new Date(end);
        console.log(
            '🚀 ~ file: index.js ~ line 550 ~ Customers ~ endDate',
            endDate
        );
        let formatEndDate = endDate.toDateString().split(' ');
        let endDateMonth = months[formatEndDate[1]];
        let endDateApi =
            formatEndDate[3] + '-' + endDateMonth + '-' + formatEndDate[2];

        setStartDateApi(startDateApi);
        setEndDateApi(endDateApi);
        startDateApiRef.current = startDateApi;
        endDateApiRef.current = endDateApi;
        setStartDate(start);
        setEndDate(end);
        resetData();
        getRestaurantOrderList()
    };

    // This function is used to call restaurant order get api
    const getRestaurantOrderList = async (skip = 0) => {
        setLoading(true);
        let statusCode = 0;
        let params = "";
        if (searchedOrderNo && searchedOrderNo.length > 0) {
            params = `&orderID=${searchedOrderNo}`
        }
        const apiUrl = `${API_URL}restaurant-order?orderDateFrom=${startDateApiRef.current}&orderDateTo=${endDateApiRef.current}&limit=${limit}&skip=${skip}&billing_status=${billingTypeRef.current}&order_status=${orderStatus}${params}`
        console.log('api url of get done kot orders', apiUrl);
        fetch(apiUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            console.log('status code of get kot orders', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of get kot done orders', res);
            // items get code
            if (res.result.length > 0) {
                let array = [];

                res.result.map(item => {
                    let obj = item.data;
                    console.log("🚀 ~ file: index.js:208 ~ Customers ~ obj:", obj)
                    obj = Object.assign({ id: item._id }, obj);
                    array.push(obj);
                });

                if (res.result.length !== limit) {
                    setNext(true)
                }
                setRecords([...array])
                setLoading(false)
            } else {
                setNext(true,)
                setLoading(false,)
                setRecords([])
            }
        }).catch((err) => {
            console.log('catch block error at get kot done orders', err);
            setLoading(false);
        })
    }


    useEffect(() => {
        getRestaurantOrderList();
        setColumns([
            {
                key: "orderID",
                text: strings.order_number,
            },
            {
                key: "netAmount",
                text: strings.order_amount,
            },
            {
                key: "orderDate",
                text: strings.date,
                cell: record => {
                    return (
                        <p className="mx-2 my-2 text-capitalize" style={{ fontSize: '11px' }}>{record.orderDate} {record.orderTime}</p>
                    )
                }
            },
            {
                key: '',
                text: 'Acción',
                cell: record => {
                    return (
                        <div className='mt-2'>
                            <Button
                                theme="primary"
                                className="mb-2 mx-2"
                                style={{ width: '100px' }}
                                onClick={() => {
                                    setSelectedOrderId(record.id);
                                    setIsSidebarShow(true);
                                }}
                            >
                                {strings.view}
                            </Button>
                        </div>
                    )
                }
            }
        ])
    }, [])

    // This function is used to handle page increment
    const handlePageIncrement = () => {
        console.log('clicked on page increment')
        if (!next) {
            let skip = currentPage * limit;
            getRestaurantOrderList(skip);
            setCurrentPage(currentPage + 1)
            setPrev(false)
            setSkip(skip)
        }
    }

    // This function is used to handle page decrement
    const handlePageDecrement = () => {
        if (skip > 0) {
            let skipValue = skip - limit;
            if (skipValue >= 0) {
                getRestaurantOrderList(skipValue);
                if (skipValue === 0) {
                    setPrev(true);
                }
                setCurrentPage(currentPage - 1);
                setNext(false);
                setSkip(skipValue);
            } else {
                setPrev(true);
                setNext(false);
            }
        } else {
            setNext(false);
        }
    }

    const closeSideBar = () => {
        resetData();
        getRestaurantOrderList();
        setIsSidebarShow(false);
    }

    const resetData = () => {
        setSkip(0);
        setCurrentPage(1);
        setPrev(true);
        setNext(false)
    }

    const handleOnChangeBillingStatus = (event) => {
        setBillingType(event.target.value)
        billingTypeRef.current = event.target.value;
        getRestaurantOrderList()
    }

    useEffect(() => {
        billingTypeRef.current = billingType;
    }, [billingType])

    const handleDownloadRestaurantReport = () => {
        setIsBtnLoading(true)

        let params = "";
        if (searchedOrderNo && searchedOrderNo.length > 0) {
            params = `&orderID=${searchedOrderNo}`;
        }
        let url = `${API_URL}restaurant-order?orderDateFrom=${startDateApi}&orderDateTo=${endDateApi}&limit=${limit}&skip=${skip}&billing_status=${billingTypeRef.current}&type=csv${params}`;
        console.log('url for downloa restaurant report', url);
        fetch(`${url}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {
                console.log('sales download csv api response ......', data);
                setIsBtnLoading(false)
                let csvContent =
                    'data:text/csv;charset=utf-8,' +
                    data.map(e => e.join(',')).join('\n');
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', 'ebol_restaurant_report.csv');
                document.body.appendChild(link);

                link.click();
            })
            .catch(err => {
                setIsBtnLoading(false);
                console.log('sales download csv api error ......', err);
            });
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Offcanvas
                show={isSidebarShow}
                placement="end"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>{strings.restaurant}</Offcanvas.Title>
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            swal
                                .fire({
                                    title: '',
                                    text:
                                        '¿Desea cerrar esta pestaña?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'Cancelar'
                                })
                                .then(response => {
                                    if (response.isConfirmed) {
                                        resetData();
                                        getRestaurantOrderList();
                                        setIsSidebarShow(false);
                                    }
                                });
                        }}
                    ></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <RestaurantOrderDetails
                        orderID={selectedOrderId}
                        closeSideBar={closeSideBar}
                    />
                </Offcanvas.Body>
            </Offcanvas>
            <Row noGutters className="page-header py-4">
                <PageTitle
                    sm="4"
                    title={strings.restaurant_report}
                    className="text-sm-left"
                />
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <FormInput
                                type="text"
                                value={searchedOrderNo}
                                placeholder={strings.search_by_order_no}
                                onChange={e => {
                                    setSearchedOrderNo(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                    console.log('event key press trigered', e);
                                    var code = e.keyCode || e.which;
                                    console.log('code', code)
                                    if (code === 13) {
                                        console.log('entered pressed')
                                        console.log('searchOrderNo', searchedOrderNo);
                                        resetData();
                                        getRestaurantOrderList(0)
                                    }
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <DateRangePicker
                                initialSettings={{
                                    startDate: startDate,
                                    endDate: endDate,
                                    locale: locale
                                }}
                                onApply={(event, picker) => {
                                    console.log(`picker startDate is ${picker.startDate} and endDate is ${picker.endDate}`)
                                    console.log(picker.endDate);
                                    picker.element.val(
                                        picker.startDate.format('DD/MM/YYYY') +
                                        ' - ' +
                                        picker.endDate.format('DD/MM/YYYY')
                                    );
                                }}
                                onCallback={(start, end) => handleCallback(start, end)}
                                locale={locale}
                            >
                                <input type="text" className="form-control" />
                            </DateRangePicker>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row noGutters className="page-header d-flex align-content-center">
                <Col sm="12" md="12" lg="3">
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <select
                                className="form-control"
                                onChange={e => handleOnChangeBillingStatus(e)}
                                value={billingType}
                            >
                                <option value="" disabled>
                                    Seleccione una opción
                                </option>
                                {
                                    billingTypes.map((item) => {
                                        return (
                                            <option value={item.value}>{item.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="3" md="12" sm="12">
                    <div className="mb-2 pb-2">
                        <div className="row">
                            <Button
                                theme="primary"
                                className="mb-1 mt-3"
                                style={{ width: '50%', height: 50 }}
                                disabled={isBtnLoading ? true : false}
                                onClick={() => {
                                    handleDownloadRestaurantReport();
                                }}
                            >
                                {isBtnLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                Descargar CSV
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <div className="MainDiv">
                                <div className="table-responsive" style={{ overflowY: "auto", maxHeight: '50vh' }}>
                                    <ReactDatatable
                                        config={config}
                                        records={records}
                                        columns={columns}
                                        loading={loading}
                                    />
                                    <nav className="d-flex justify-content-center align-items-center">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageDecrement}
                                                    style={
                                                        prev
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Anterior
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    href="javascript:;"
                                                    onClick={handlePageIncrement}
                                                    style={
                                                        next
                                                            ? { cursor: 'not-allowed', color: 'gray' }
                                                            : null
                                                    }
                                                >
                                                    Próxima
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantReport