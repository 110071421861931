import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import { API_URL } from "../../../../config";
import { strings } from "../../../../localization";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      notifications: [],
      isLoading: true
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    if (!this.state.visible) {
      this.getNotifications();
      this.setState({
        isLoading: true
      })
    }
    this.setState({
      visible: !this.state.visible
    });
  }

  // This function is used to call restaurant kot get api
  getNotifications = async () => {
    this.setState({
      isLoading: true
    })
    let statusCode = 0;
    const apiUrl = `${API_URL}get-notification/restaurant`
    fetch(apiUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    }).then((res) => {
      statusCode = res.status;
      return res.json();
    }).then((res) => {
      console.log("🚀 response of notifications api:", res)
      if (statusCode === 200) {
        if (res && res.result && res.result.length > 0) {
          const result = res.result;
          this.setState({
            notifications: result
          })
        } else {
          this.setState({
            notifications: []
          })
        }
      }
      this.setState({
        isLoading: false
      })
    }).catch((err) => {
      console.log('catch block error', err);
      this.setState({
        isLoading: false
      })
    })
  }

  componentDidMount() {
    this.getNotifications()
  }

  getTimeAgoStringFromDate = (inputDate) => {
    const now = new Date();
    const date = new Date(inputDate); // Ensure input is a valid date
    const diff = Math.floor((now - date) / 1000); // Difference in seconds

    if (diff < 60) return `${diff} ${strings.seconds_ago}`;
    if (diff < 3600) return `${Math.floor(diff / 60)} ${strings.minutes_ago}`;
    if (diff < 86400) return `${Math.floor(diff / 3600)} ${strings.hours_ago}`;
    if (diff < 604800) return `${Math.floor(diff / 86400)} ${Math.floor(diff / 86400) <= 1 ? strings.day_ago : strings.days_ago}`;
    if (diff < 2592000) return `${Math.floor(diff / 604800)} ${strings.weeks_ago}`;
    if (diff < 31536000) return `${Math.floor(diff / 2592000)} ${strings.months_ago}`;

    return `${Math.floor(diff / 31536000)} ${strings.years_ago}`;
  };

  render() {
    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              2
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
          style={{ overflow: 'hidden', maxHeight: '50vh' }}
        >
          <div style={{ height: 'auto' }}>
            <div style={{ overflow: 'auto', maxHeight: '40vh' }}>
              {
                this.state.notifications.length > 0 && this.state.notifications.map((item) => {
                  const data = item.data;
                  const notification = data.notification ? data.notification : "";
                  return (
                    <DropdownItem>
                      <div className="notification__content">
                        <span className="notification__category">{notification.title ? notification.title : ""}</span>
                        <p>
                          {notification.body ? notification.body : ""}
                        </p>
                      </div>
                      <p style={{ marginLeft: 'auto' }}>{data.created_date ? this.getTimeAgoStringFromDate(data.created_date) : ""}</p>
                    </DropdownItem>
                  )
                })
              }
            </div>
            {/* <div>
              <DropdownItem className="notification__all text-center">
                View all Notifications
              </DropdownItem>
            </div> */}
          </div>
        </Collapse>
      </NavItem>
    );
  }
}
