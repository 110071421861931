import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";


// TOOD: NOTIFICATION CODE
// import { getToken } from "firebase/messaging";
// import { messaging } from "./firebase";

export default function App() {
  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    console.log('permission', permission)
    if (permission === "granted") {
      // const token = await getToken(messaging, {
      //   vapidKey: 'BLp8XL4qume3MzVPr5n1UnMwvjxChZCLZ2xRpGx47HUZylmjiylVJ4zihfMVnDI8p-Dl1a-R1GCN_ybGwd3DEwc',
      // });

      //We can send token to server
      // console.log("Token generated : ", token);
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  // TOOD: NOTIFICATION CODE
  // useEffect(() => {
  //   requestPermission();
  // }, []);
  return (
    <Router>
      <div>
        {routes.map((route, index) => {
          return (
            <>
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
              {/* <Route component={Errors} /> */}
              {/* <Redirect to="/404" /> */}
              {/* <Route path="*" component={NotFoundRoute} /> */}
            </>
          );
        })}
      </div>
    </Router>
  )
};
