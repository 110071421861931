import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row, FormInput, FormTextarea, Alert } from 'shards-react'
import PageTitle from '../../components/common/PageTitle'
import { strings } from '../../localization'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CurrentKOTOrders from './CurrentKOTOrders';
import PastKOTOrders from './PastKOTOrders';

function RestaurantKOTOrders() {
    const [activeTab, setActiveTab] = useState('current'); // Default active tab

    return (
        <Container fluid className="main-content-container px-4 mx-10">
            <Row>
                <Col>
                    <Card small className="mb-4 tableCard mt-5">
                        <CardBody className="p-3 b-3">
                            <PageTitle
                                sm="4"
                                title={strings.orders}
                                className="text-sm-left page-title"
                            />
                            <hr />
                            <Tabs
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                                activeKey={activeTab}
                                onSelect={(tab) => setActiveTab(tab)}
                            >
                                <Tab eventKey="current" title={strings.current_orders}>
                                    <CurrentKOTOrders isActive={activeTab === "current"} />
                                </Tab>
                                <Tab eventKey="past" title={strings.past_orders}>
                                    <PastKOTOrders isActive={activeTab === "past"} />
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantKOTOrders