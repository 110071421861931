import React, { useCallback, useEffect, useRef, useState } from 'react'
import { API_URL, LIMIT } from '../../config';
import KOTOrderList from '../../components/Restaurant/KOTOrderList';

function PastKOTOrders({ isActive }) {
    console.log('pastKOTOrder isActive', isActive)

    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [limit, setLimit] = useState(LIMIT);
    // pagination states
    const [skip, setSkip] = useState(0);
    const skipValueRef = useRef(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [prev, setPrev] = useState(true);
    const [next, setNext] = useState(false);

    const config = {
        page_size: 10000000000,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    // refresh functionality states
    const intervalIdRef = useRef(null);
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const getCurrentKOTOrderList = (skip = 0) => {
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-kot?status=done&limit=${limit}&skip=${skip}`
        console.log('api url of get done kot orders', apiUrl);
        fetch(apiUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            console.log('status code of get kot orders', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of get kot done orders', res);
            if (statusCode === 200) {
                const data = res['result'];
                console.log('data of kot done orders', data)
                if (data && data.length > 0) {
                    let orders = [];
                    data.map((item, index) => {
                        const orderData = item['data'];
                        let dataArray = orderData['dataArray'] ? orderData['dataArray'] : [];

                        const kotID = item['_id'];
                        const kotDisplayID = orderData['kotID'];
                        const tableDetail = orderData['tableDetail'];
                        const orderDetail = orderData['orderDetail'];
                        const orderDisplayID = orderDetail['orderID'];
                        const tableName = tableDetail ? tableDetail['tableName'] : ""

                        dataArray.map((foodItem, foodIndex) => {
                            console.log('foodItem', foodItem);
                            foodItem['kotID'] = kotID;
                            foodItem['tableName'] = tableName;
                            foodItem['kotDisplayID'] = kotDisplayID;
                            foodItem['orderDisplayID'] = orderDisplayID;
                            orders.push(foodItem);
                        })
                    })
                    console.log('orders', orders)
                    setRecords([...orders]);
                    setLoading(false);
                } else {
                    setNext(true);
                    setRecords([]);
                    setLoading(false);
                }
            } else {
                const msg = res['message'] ? res['message'] : "Something went wrong!"
                setLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at get kot done orders', err);
            setLoading(false);
        })
    }

    const getCurrentKOTOrderData = useCallback(() => {
        if (isActive) {
            getCurrentKOTOrderList(skipValueRef.current);
            // Call the function every 5 seconds (5000 milliseconds)
            intervalIdRef.current = setInterval(() => getCurrentKOTOrderList(skipValueRef.current), 5000);
        }
    }, [isActive]);

    useEffect(() => {
        getCurrentKOTOrderData()
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [getCurrentKOTOrderData, isActive])


    // This function is used to handle page increment
    const handlePageIncrement = () => {
        console.log('clicked on page increment')
        if (!next) {
            let skip = currentPage * limit;
            getCurrentKOTOrderList(skip);
            setCurrentPage(currentPage + 1)
            setPrev(false)
            setSkip(skip)
            skipValueRef.current = skip;
        }
    }

    // This function is used to handle page decrement
    const handlePageDecrement = () => {
        if (skip > 0) {
            let skipValue = skip - limit;
            if (skipValue >= 0) {
                getCurrentKOTOrderList(skipValue);
                if (skipValue === 0) {
                    setPrev(true);
                }
                setCurrentPage(currentPage - 1);
                setNext(false);
                setSkip(skipValue);
                skipValueRef.current = skipValue;
            } else {
                setPrev(true);
                setNext(false);
            }
        } else {
            setNext(false);
        }
    }

    return (
        <>
            <KOTOrderList
                loading={loading}
                records={records}
                limit={limit}
                config={config}
                isCurrentOrders={false}
                next={next}
                prev={prev}
                handlePageIncrement={handlePageIncrement}
                handlePageDecrement={handlePageDecrement}
            />
        </>
    )
}
export default PastKOTOrders