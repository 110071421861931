import React from "react";
import { Nav } from "shards-react";

import UserActions from "./UserActions";
import Notifications from "./Notifications";

export default () => {
  let hasRestaurantPermission = false;
  let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
  if (organizationData !== undefined && organizationData !== null &&
    organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
  ) {
    hasRestaurantPermission = organizationData['data']['restaurantModule'];
  }
  return (
    <Nav navbar className="border-left flex-row">
      {hasRestaurantPermission && <Notifications refresh={true} />}
      <UserActions />
    </Nav>
  )
}
